
import { lazyLoadSlider } from '~/mixins/lazyLoadSlider';
import MediaSlide from 'portal/components/ui/swiper/MediaSlide.vue';
import VSwiperPaginationNew from 'portal/components/ui/swiper/VSwiperPaginationNew.vue';

export default {
    name: 'VSwiper',

    components: {
        VSwiperPaginationNew,
        MediaSlide,
    },

    mixins: [lazyLoadSlider],

    props: {
        /**
         * ОПЦИИ ДЛЯ СЛАЙДЕРА
         *
         * Информация для слайдов внутри слайдера.
         * Прокидывается в пропсы слота
         */
        slides: {
            type: Array,
            default: () => [],
        },

        /**
         * Вертикальный вид слайдера
         */
        vertical: {
            type: Boolean,
            default: false,
        },

        /**
         * Скорость прокрутки слайдера
         */
        speed: {
            type: Number,
            default: 1000,
        },

        /**
         * Кол-во отображаемых за раз слайдов
         */
        slidesPerView: {
            type: Number,
            default: 1,
        },

        /**
         * Расстояние между слайдами
         */
        spaceBetween: {
            type: Number,
            default: 0,
        },

        /**
         * Включить бесконечную прокрутку
         */
        loop: {
            type: Boolean,
            default: false,
        },

        /**
         * Включить бесконечную прокрутку
         */
        autoplayDelay: {
            type: Number,
            default: 0,
        },

        /**
         * Отключить бесконечную прокрутку при взаимодействии со слайдером
         */
        disableOnInteraction: {
            type: Boolean,
            default: false,
        },


        /**
         * Любые другие опции для слайдера,
         * будут применены с высшим приоритетом
         */
        customOptions: {
            type: Object,
            default: () => ({}),
        },

        /**
         * ПАРАМЕТРЫ ДЛЯ ЭЛЕМЕНТОВ УПРАВЛЕНИЯ
         *
         * Подключить кнопки для смены слайдов
         */
        controls: {
            type: Boolean,
            default: false,
        },

        /**
         * Размеры, которые определяют отступы внутри контейнера для элементов управления.
         */
        size: {
            type: String,
            default: 'medium',
            validator: val => [
                'xsmall',
                'small',
                'medium',
                'large',
                'zero',
            ].includes(val),
        },

        /**
         * Размер для кнопок переключения слайдов. Совпадает с неймингом размера кнопок
         */
        controlsSize: {
            type: String,
            default: 'small',
            validator: val => ['xsmall', 'small'].includes(val),
        },

        /**
         * Цвет для кнопок переключения слайдов. Совпадает с неймингом цветов кнопок
         */
        controlsColor: {
            type: String,
            default: 'primary',
            validator: val => ['primary'].includes(val),
        },

        /**
         * Вертикальная позиция кнопок внутри контейнера
         */
        controlsPosition: {
            type: String,
            default: 'center',
            validator: val => ['top', 'bottom', 'center'].includes(val),
        },

        /**
         * ПАРАМЕТРЫ ДЛЯ MEDIA СЛАЙДА
         *
         * Подключить кнопки для упрвления плеером в теге video
         */
        videoControls: {
            type: Boolean,
            default: false,
        },

        /**
         * ПАРАМЕТРЫ ДЛЯ MEDIA СЛАЙДА
         *
         * Флаг параметров построения пути к изображению
         */
        imageOptions: {
            type: Boolean,
            default: true,
        },

        /**
         * ПАРАМЕТРЫ ДЛЯ MEDIA СЛАЙДА
         *
         * Добавляет псевдоэлкмент с градиентом
         */
        galleryShadow: {
            type: Boolean,
            default: true,
        },

        /**
         * ПАРАМЕТРЫ ДЛЯ MEDIA СЛАЙДА
         *
         * Ставит вместо cover -> contain
         */
        galleryContain: {
            type: Boolean,
            default: false,
        },

        /**
         * Дизейблит стрелки слайдера
         * можно использовать, например, когда в галерее пользователь прозумировал изображение
         */
        disableControls: {
            type: Boolean,
            default: false,
        },

        /**
         * Подключить пагинацию
         */
        pagination: {
            type: Boolean,
            default: false,
        },

        /**
         * Цвет буллитов пагинации
         */
        paginationColor: {
            type: String,
            default: 'base',
            validator: val => [
                'base',
                'dark',
            ].includes(val),
        },

        /**
         * Позиция пагинации внутри контейнера
         */
        paginationPosition: {
            type: String,
            default: 'bottom',
            validator: val => ['top', 'top-right', 'bottom', 'bottom-right'].includes(val),
        },

        /**
         * Режим позиционирования, когда пагинация находится между кнопками
         * и автоматически центрируется по вертикали
         */
        paginationIntoButtons: {
            type: Boolean,
            default: false,
        },

        /**
         * Скрывает элементы управления,
         * отображаются по наведению
         */
        hoverable: {
            type: Boolean,
            default: false,
        },

        /**
         * ОБЩИЕ ПАРАМЕТРЫ
         *
         * Включает обновление слайдера при изменении его размеров.
         * Помогает решать некоторые баги в работе Swiper
         */
        updateByResize: {
            type: Boolean,
            default: false,
        },

        /**
         * Инициировать слайдер заново, нужно, например,
         * при обновлении контента (напр, кол-ва слайдов)
         */
        forceUpdated: {
            type: [Number, String, Boolean],
            default: 0,
        },

        /**
         * Инициировать переключение на следующий слайд
         */
        forceNext: {
            type: [Number, String, Boolean],
            default: 0,
        },

        /**
         * Инициировать переключение на предыдущий слайд
         */
        forcePrev: {
            type: [Number, String, Boolean],
            default: 0,
        },

        /**
         * Индекс начального слайда
         */
        initialIndex: {
            type: Number,
            default: 0,
        },

        /**
         * Включает режим галереи без слота
         */
        gallery: {
            type: Boolean,
            default: false,
        },

        // включает vanilla-lazyload для слайдера
        lazy: {
            type: Boolean,
            default: false,
        },

        /**
         * Брейкпоинты imgProxy, 100vw в данном случае равен размеру брейкпоинта каждого устройства,
         * которые являются общими для Nuxt Device is и Nuxt Images (nuxt.config.js)
         */
        breakpoints: {
            type: String,
            default: 'mobile:100vw tablet:100vw laptop:100vw desktop:100vw',
        },

        /**
         * Режим слайдера на весь экран, чтобы при скролле не было видно паддингов по бокам
         */
        wideMode: {
            type: Boolean,
            default: false,
        },

        centeredSlides: {
            type: Boolean,
            default: false,
        },

        /**
         * Инициализация слайдера с одним слайдом
         */
        initWithOneSlide: {
            type: Boolean,
            default: false,
        },

        /**
         * Затемнения изображений для MediaSlide
         */
        shadows: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            element: null,
            slider: null,
            resizeObserver: null,
            activeIndex: this.initialIndex,
        };
    },

    computed: {
        classes() {
            return {
                [this.$style[`_${this.size}`]]: this.size,
                [this.$style[`_controls-${this.controlsSize}`]]: this.controlsSize,
                [this.$style[`_controls-${this.controlsPosition}`]]: this.controlsPosition,
                [this.$style[`_pagination-${this.paginationPosition}`]]: this.paginationPosition,
                [this.$style._hoverable]: this.hoverable,
                [this.$style._wide]: this.wideMode,
                [this.$style['_into-buttons']]: this.paginationIntoButtons,
            };
        },

        isFewSlides() {
            return this.slides?.length > this.slidesPerView;
        },

        isPrevDisabled() {
            return this.loop ?
                this.disableControls :
                this.disableControls || this.activeIndex === 0;
        },

        isNextDisabled() {
            return this.loop ?
                this.disableControls :
                this.disableControls || this.activeIndex === this.slides.length - 1;
        },
    },

    watch: {
        forceUpdated() {
            this.update();
        },

        forceNext() {
            this.slider.slideNext(this.speed);
        },

        forcePrev() {
            this.slider.slidePrev(this.speed);
        },

        slides() {
            this.$nextTick(() => {
                if (!this.slider) {
                    this.initComponent();
                } else {
                    this.update();
                }
            });
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.element = this.$refs.slider;
            this.initComponent();
        });
    },

    beforeDestroy() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect(this.element);
        }

        this.slider?.destroy();
    },

    methods: {
        onNext() {
            this.slider.slideNext();
        },

        onPrev() {
            this.slider.slidePrev();
        },

        initComponent() {
            if (this.isFewSlides || this.initWithOneSlide) {
                this.initSlider();

                if (this.element && this.updateByResize) {
                    this.resizeObserver = new ResizeObserver(this.update);
                    this.resizeObserver.observe(this.element);
                }
            }
        },

        initSlider() {
            const options = {
                direction: this.vertical ? 'vertical' : 'horizontal',
                speed: this.speed,
                slidesPerView: this.slidesPerView,
                spaceBetween: this.spaceBetween,
                loop: this.loop,
                allowTouchMove: true,
                initialSlide: this.initialIndex,
                centeredSlides: this.centeredSlides,

                autoplay: !this.autoplayDelay ? false : { delay: this.autoplayDelay },

                modules: [
                    this.$SwiperModules.Autoplay,
                ],

                on: {
                    activeIndexChange: swiper => {
                        this.activeIndex = swiper.realIndex;
                        this.$emit('slide-change', swiper.realIndex);
                    },

                    /**
                     * После инициализации swiper, инициирует vanilla-lazyLoad
                     */
                    afterInit: swiper => {
                        if (this.lazy) {
                            this.handleInitLazyLoad(swiper);
                        }

                        if (this.autoplayDelay) {
                            const timeout = setTimeout(() => {
                                if (!swiper.destroyed) {
                                    swiper.autoplay.start();
                                }

                                clearTimeout(timeout);
                            }, 100);
                        }
                    },

                    autoplayTimeLeft: (swiper, time, progress) => {
                        this.$emit('autoplay-time-left', { time, progress });
                    },
                },

                ...this.customOptions,
            };

            this.slider = new this.$Swiper(this.element, options);
            this.activeIndex = this.initialIndex;

            this.$emit('load', this.slider);
        },

        update() {
            this.slider?.destroy();

            this.$nextTick(() => {
                if (this.isFewSlides || this.initWithOneSlide) {
                    this.initSlider();
                }
            });
        },

        /**
         * Вручную переключает на следующий слайд, через эмит @video-ended,
         * если видео проигралось до конца. Т.к. если это видео, то мы выключаем autoplay.
         * После смены слайда отработает метод handleChangeSliderAutoplay и запустит autoplay снова.
         * @public
         */
        handleVideoEnded() {
            this.slider.slideNext();
        },
    },
};
