import { render, staticRenderFns } from "./VSwiperPaginationNew.vue?vue&type=template&id=48a8790e"
import script from "./VSwiperPaginationNew.vue?vue&type=script&lang=js"
export * from "./VSwiperPaginationNew.vue?vue&type=script&lang=js"
import style0 from "./VSwiperPaginationNew.vue?vue&type=style&index=0&id=48a8790e&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports