
export default {
    name: 'MediaSlide',

    props: {
        slideIndex: {
            type: Number,
            default: 0,
        },

        video: {
            type: String,
            default: '',
        },

        image: {
            type: String,
            required: true,
        },

        controls: {
            type: Boolean,
            default: false,
        },
        
        active: {
            type: Boolean,
            default: false,
        },

        title: {
            type: String,
            default: 'image',
        },

        shadows: {
            type: Boolean,
            default: true,
        },

        prePreview: {
            type: String,
            default: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
        },

        breakpoints: {
            type: String,
            default: 'mobile:100vw tablet:100vw laptop:100vw desktop:100vw',
        },

        modifiers: {
            type: Object,
            default: () => ({}),
        },

        contain: {
            type: Boolean,
            default: false,
        },

        imageOptions: {
            type: Boolean,
            default: true,
        },

        loadFirstImg: Boolean,
    },

    computed: {
        ImgAttrs() {
            if (!this.imageOptions) {
                return { src: this.image };
            }
            
            const propsAttrs = {};

            if (this.loadFirstImg && this.slideIndex === 0) {
                propsAttrs['sizes'] = this.sizeOptions.sizes;
                propsAttrs['srcset'] = this.sizeOptions.srcset;

                return { src: this.image, ...propsAttrs };
            }

            propsAttrs['data-src'] = this.previewImg;
            propsAttrs['data-lazy-src'] = this.image;
            propsAttrs['data-lazy-sizes'] = this.sizeOptions.sizes;
            propsAttrs['data-lazy-srcset'] = this.sizeOptions.srcset;

            return { src: this.prePreview, ...propsAttrs };
        },

        sizeOptions() {
            const options = {
                sizes: this.breakpoints,
                modifiers: { ...this.modifiers },
            };

            return this.$img.getSizes(this.image, options);
        },

        previewImg() {
            const options = {
                width: 400,
                quality: 40,
                blur: 60,
            };

            return this.$img(this.image, options);
        },
    },

    watch: {
        active: {
            immediate: true,
            handler: function(val) {
                this.$nextTick(() => {
                    if (!this.$refs.video) {
                        return;
                    }

                    val ? this.handlePlayVideo() : this.handlePauseVideo();
                });
            },
        },
    },

    methods: {
        handlePlayVideo() {
            this.$refs.video.play();
        },

        handlePauseVideo() {
            const player = this.$refs.video;
            player.pause();

            setTimeout(() => {
                player.currentTime = 0;
            }, 300);
        },

        onVideoEnded() {
            this.handlePauseVideo();
            this.$emit('video-ended');
        },
    },
};
