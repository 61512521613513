// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VSwiperPaginationNew_yoIL-{align-items:center;display:flex;justify-content:center}.VSwiperPaginationNew_yoIL-._base_ApTT0 .bullet_9A7DM{background-color:#b69cff;border-radius:50%}.VSwiperPaginationNew_yoIL-._base_ApTT0 .bullet_9A7DM._active_7Kpen:after{background-color:#17181c}.VSwiperPaginationNew_yoIL-._dark_aJDCn .bullet_9A7DM{background-color:#fff6}.VSwiperPaginationNew_yoIL-._dark_aJDCn .bullet_9A7DM._active_7Kpen:after{background-color:#fff}.VSwiperPaginationNew_yoIL-._medium_XpqLO .bullet_9A7DM{height:6px;width:6px}.VSwiperPaginationNew_yoIL-._medium_XpqLO .bullet_9A7DM:not(.VSwiperPaginationNew_yoIL-._medium_XpqLO .bullet_9A7DM:last-child){margin-right:.8rem}.VSwiperPaginationNew_yoIL-._medium_XpqLO._withProgress_x-qc7 .bullet_9A7DM._active_7Kpen{width:2.4rem}.VSwiperPaginationNew_yoIL-._withProgress_x-qc7 .bullet_9A7DM:after{transform:scaleX(var(--progress));transform-origin:left}.bullet_9A7DM{position:relative;transition:width .3s ease}.bullet_9A7DM:after{background-color:#abaebb;border-radius:50%;content:\"\";height:100%;left:0;position:absolute;top:0;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-tablet": "5.6rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"VSwiperPaginationNew": "VSwiperPaginationNew_yoIL-",
	"_base": "_base_ApTT0",
	"bullet": "bullet_9A7DM",
	"_active": "_active_7Kpen",
	"_dark": "_dark_aJDCn",
	"_medium": "_medium_XpqLO",
	"_withProgress": "_withProgress_x-qc7"
};
module.exports = ___CSS_LOADER_EXPORT___;
